import React, { useState } from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import Planterdetailslist from 'components/planter-details/planterdetailslist';
import Loginvisitor from 'components/initiative/loginvisitor';
import Registeryour from './registeryour-plantation';


// markup
const Planterdetails = () => {
  const [openRegister, setOpenRegister] = useState(false)
  const registerPage = () => {
    setOpenRegister(true)
  }
  return (
    <>
      <SEO title='Forests By Heartfulness | Planterdetalis' />
      <Layout>
        <Loginvisitor onClick={registerPage} />
        <section id='planterdetails'>
          <Planterdetailslist />
          {openRegister ?
            <Registeryour /> : null}
        </section>
      </Layout>
    </>
  );
};

export default Planterdetails;