import React, { useState, useEffect } from 'react';

import { navigate } from 'gatsby';

import 'assets/styles/pages/planterdetailslist.scss';

import treedate from 'assets/images/initiative/tree-date.png';

import PlantationListDetails from 'services/plantation/plantation.service';

import moment from 'moment';

import { Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import Viewthubnailslider from 'components/register-plantation/viewthumbnail-slider';

import Pagination from "react-js-pagination";

import noimage from "assets/images/noImage.png"
import { doLogout } from 'components/Auth';
import infoIcon from 'assets/images/icons/info-icon.png';

export default function Planterdetailslist() {
  const ds = new PlantationListDetails();


  const [plantationList, setPlantationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = async (pageNumber) => {
    await setActivePage(pageNumber)
    let inputs = {
      "pageNumber": pageNumber - 1,
      "pageSize": 10,
      "projectId": 1,
      "reviewStatus": 1,
      "toBeFiltered": false
    }
    setLoading(true);
    await ds.listPlantation({ inputs })
      .then(data => {
        if (data.status === 200) {
          setPlantationList(data.data);
          setLoading(false)
          window.scrollTo(0, 0)
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        toast.error(error.response.data.message);
      }
      );
  }
  const listPlanters = async () => {
    await setLoading(true);
    let inputs = {
      "pageNumber": 0,
      "pageSize": 10,
      "projectId": 1,
      "reviewStatus": 1,
      "toBeFiltered": false
    }
    await ds.listPlantation({ inputs })
      .then(data => {
        if (data.status === 200) {
          setPlantationList(data.data);
          window.scrollTo(0, 0);
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 404) {
            toast.error("Authentication failed");
            setTimeout(function () {
              doLogout()
              navigate("/")
              setLoading(false);
            }, 3000)
          }
        }
      }
      );
  };
  const viewPage = (data) => {
    let registerData = data
    let path1 = "/view-sapling"
    let pathSet = `?id=${registerData.id}`
    navigate(path1 + pathSet)
  }
  const addmorePage = (id) => {
    let registerData = id
    let path1 = "/plantation-grid"
    let pathSet = `?id=${registerData.id}`
    navigate(path1 + pathSet)
  }
  const editPage = (data) => {
    let registerData = data
    let path1 = "/edit-sapling"
    let pathSet = `?id=${registerData.id}&growthStatus=${data.isGrowthStatusModified}`
    navigate(path1 + pathSet)
    //  navigate("/edit-sapling", { state: { registerData } })
  }
  useEffect(() => {
    const storageInfo = (typeof window !== 'undefined') ? JSON.parse(localStorage.getItem("hfnAuthUserDetails")) : null;
    if (storageInfo) {
      listPlanters();
    }
  }, []);
  return (
    <>
      <div className='login-visitor'>
      </div>
      <div className='initiative-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h4 className='plantation-title'>  Your Plantation </h4>
              <div className='row'>
                {plantationList && plantationList.totalNoOfRecords > 0 ? <>

                </> : <p>No Records Found</p>}
                {plantationList.plantationDetails && plantationList.plantationDetails.map((item, index) => {
                  var timestamp = item.plantation.plantationDate
                  var date = new Date(timestamp);
                  let loopImages = item.plantation.trackingDetails && item.plantation.trackingDetails[0] ? item.plantation.trackingDetails[0] : ""
                  return (
                    <div className='col-md-6' key={item.plantation.id}>
                      <div className='iniative-card' >
                        <div className='wid-40 padding-10'>
                          {loopImages.imagesLogs && loopImages.imagesLogs.length !== 0 ?
                            <Viewthubnailslider images={loopImages.imagesLogs} key={item.id} /> :
                            <img src={noimage} alt="default Image" />}

                          {/* {item.plantation.trackingDetails && item.plantation.trackingDetails.map(item => {
                            return <img data-src={item.imagesLogs[0].imageUrl} className='lazyload' alt={item.alt} />
                          })} */}

                        </div>
                        <div className='iniativecard-body wid-60'>
                          <h3 className='iniativecard-title text-bold'>
                            {item.plantation.project.name}
                          </h3>

                          {item.totalSpeciesCount && item.totalSpeciesCount ? <p className='green-clr sapling-text'>
                            <span>

                              <img src={treedate} className='img-fluid treedate-icon' alt='Tree Date' />&nbsp;&nbsp;
                            </span>  {item.totalSpeciesCount} Saplings
                          </p> : null}
                          <p className='text-para'>
                            Reg Id # {item.plantation.registrationId}
                          </p>
                          <p className='date-text'>


                            {moment(date).format(("ddd, MMM D YYYY"))}
                          </p>
                          {item.plantation.reviewStatus === "PENDING" ? <button className='btn adddmore-spaling' onClick={() => { addmorePage(item.plantation) }}>
                            + Add more Saplings
                          </button> : null}
                          {item.plantation.reviewStatus === "IN_PROGRESS" ? <p style={{ color: "orange" }}>Admin Approval In Progress</p> : null}
                          {item.plantation.reviewStatus === "REJECTED" ? <p className='hastric-color'>Your plantation is rejected &nbsp;
                            <OverlayTrigger
                              key='top'
                              placement='top'
                              overlay={
                                <Tooltip id='tooltip-top'>
                                  <strong>{item.plantation.reviewComment}</strong>.
                                </Tooltip>
                              }
                            >
                              <img src={infoIcon} alt="info" width="18px" height="18px" /></OverlayTrigger>
                          </p> : null}
                          {item.plantation.reviewStatus === "APPROVED" ?
                            <div className='dis-flex'>
                              <button className='btn btn-tracknow margin-rt' onClick={() => { viewPage(item.plantation) }}> View </button>
                              <button className='btn btn-tracknow mg-left-15' onClick={() => { editPage(item.plantation) }}> Edit </button>
                            </div>
                            : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {(loading === false && plantationList.totalNoOfRecords > 10) ? <div >

                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={plantationList.totalNoOfRecords}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              </div> : null}
            </div>
          </div>
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
